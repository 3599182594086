import React from 'react';

import { Page } from 'src/app/components';

const UserGuide = () => (
  <Page>
    <Page.Main className="pt-16 mx-auto max-w-screen-2xl">
      <iframe
        title="User Guide"
        src="./vulcain-user-guide.pdf"
        className="w-full"
        style={{ minHeight: 'calc(100vh - 4rem)' }}
      />
    </Page.Main>
  </Page>
);

export default UserGuide;
